.taod-cover {
    display: block;
    position: relative;
    width: 100%;
    //height: 700px;
    padding: 50px 0px 0px;
    background-color: $taod-grey;
    z-index: 0;


    img {
        display: block;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
    }

    .conference-title {
        padding: 25px 0px;
        text-align: center;

        h1 {
            font-size: 22px;
            color: white;
            margin: -10px auto 0px;
            letter-spacing: 2px;
        }

        h2 {
            font-size: 19px;
            color: white;
            font-weight: lighter;
            margin: -10px auto 0px;
            text-transform: none;
        }
    }

    .cover-cta-container {
        padding: 15px 0px 70px;
        text-align: center;
    }
}

.btn-red {
    color: white;
    background-color: $taod-highlight;

    &:hover {
        color: white;
        background-color: $taod-highlight-hover;
    }
}

.talk-titles {

    ul {
        margin-top: 40px;
    }

    li {
        list-style: none;
        list-style-type: none;
        list-style-image: none;
        display: block;
        position: relative;
        width: 60%;
        margin: 0px auto 30px;
        font-size: 20px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: -5px;
            right: 0;
            bottom: -5px;
            left: 0;
            border-radius: 5px;
            z-index: -10;
            background-color: $taod-highlight-hover;
            opacity: 0;
            transition: opacity 0.2s;
        }

        &:hover {
            &::before {
                opacity: 1;
            }

            a {
                color: white;
                //transition: none !important;
            }
        }
    }
}

.taod-speakers {
    padding: 30px 0px;
}

.speakers-title {
    position: relative;
    text-align: center;
    //margin-bottom: 20px;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: -30px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: rgb(228, 228, 228);
    }
    /*
    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -30px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: rgb(228, 228, 228);
    }
    */
}

.speaker-item {
    padding: 50px 0px;

    &:not(:last-child) {
        border-bottom: solid 1px rgb(207, 207, 207);
    }

    h3 {
        font-size: 22px;
        color: $taod-highlight-hover;
    }

    .speaker-info {
        display: block;
        width: 100%;

        &:nth-child(4) {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
    }

    .speaker-photo {
        display: block;
        float: left;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        overflow: hidden;

        img {
            height: 60px;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    .speaker-description {
        display: block;
        float: left;

        h4 {
            display: block;
            height: 30px;
            line-height: 30px;
            margin: 0 0 0 20px;
        }

        p {
            display: block;
            height: 30px;
            line-height: 30px;
            margin: 0 0 0 20px;
        }

        @media screen and (max-width: 768px) {
            h4 {
                height: auto;
                margin: 0;
            }

            p {
                height: auto;
                line-height: 20px;
                margin: 0;
            }
        }
    }

    .talk-abstract {
        display: block;
        width: 100%;
        padding: 10px 0px 20px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: justify;
        color: #333;
        opacity: 0.75;

        li, p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            letter-spacing: 0px;
            text-align: justify;
            color: #333;
        }
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.clear-fix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}
